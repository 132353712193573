import * as React from 'react';
import { Dialog } from '@dx-ui/osc-dialog-v2';
import type { DialogBaseProps, DialogOptionalProps } from '@dx-ui/osc-dialog-v2';

type AlertDialog = {
  /**
   * text for the button
   */
  label: string;
} & DialogBaseProps &
  DialogOptionalProps;

/**
 * wraps the `<Dialog>` component and a button to be used in the alert
 */
export const AlertDialog: React.FC<React.PropsWithChildren<AlertDialog>> = ({
  ariaLabel,
  label,
  children,
  title,
  ...rest
}) => {
  const [open, setOpen] = React.useState(false);

  const commonProps = {
    ...rest,
    isOpen: open,
    onDismiss: () => setOpen(!open),
    dialogTrigger: (
      <button className="btn btn-primary-link" onClick={() => setOpen(true)} type="button">
        {label}
      </button>
    ),
  };

  return title ? (
    <Dialog {...commonProps} title={title}>
      {children}
    </Dialog>
  ) : ariaLabel ? (
    <Dialog {...commonProps} ariaLabel={ariaLabel}>
      {children}
    </Dialog>
  ) : null;
};

export default AlertDialog;
